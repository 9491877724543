import React, { useEffect } from "react";
import { Link } from "gatsby";
import { SEO } from "components";
import { PageHeading } from "components/layout";
import BlockContent from "@sanity/block-content-to-react";
import useMenu from "state/useMenu";
/* Terms Page
============================================================================= */

const TermsPage = ({ data }) => {
	// Config
	const { setAd } = useMenu();

	useEffect(() => {
		setAd();
	}, []);
	return (
		<>
			<SEO title="Terms & Conditions" />
			<section
				role="region"
				aria-label="Litmus Media Terms & Conditions"
				className="padding-top horizontal-padding"
			>
				<div className="w-full mx-auto">
					<BlockContent
						blocks={data.sanityTerms._rawTermsAndConditions}
						className="block-content prose-lg"
						renderContainerOnSingleChild
					/>
				</div>
			</section>
		</>
	);

	// Render
};

/* Page Query
============================================================================= */

export const pageQuery = graphql`
	{
		sanityTerms {
			_rawTermsAndConditions(resolveReferences: { maxDepth: 10 })
		}
	}
`;

/* Export
============================================================================= */

export default TermsPage;
